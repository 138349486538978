<template>
    <div class="edit-profile-detail">
        <div class="title" v-html="type === 'income' ? '연소득' : '개인자산'" />
        <div class="guide">
            <div class="text flex-row" v-for="i in [1, 2]" :key="i">
                <p class="dot m-r-8 m-b-3" />
                <span v-html="$translate(`SIGNUP_${type.toUpperCase()}_GUIDE_${i}`)" />
            </div>
        </div>
        <div class="section">
            <div
                class="section-title required"
                v-html="type === 'income' ? '연소득을 입력해주세요.' : '개인 자산을 입력해주세요.'"
            />
            <!-- <div class="button-container">
                <div
                    class="btn-normal"
                    :class="{ selected: selectedFinanceBtn === btn.id }"
                    v-for="btn in financeButtons"
                    :key="`btn-${btn.id}`"
                    v-html="btn.key"
                    @click="onClickBtn(btn.id)"
                />
            </div> -->
            <MoneyInput
                v-if="selectedFinanceBtn !== 0"
                @input="onChangeNumberVal"
                :initialVal="initialVal"
                :reset="reset"
            />
        </div>
        <!-- <div class="section">
            <div class="section-title required">정보공개 방법</div>
            <div class="selector-container">
                <div
                    class="select"
                    v-for="select in selectors"
                    :key="`select-${select.id}`"
                    @click="onClickSelector(select.id)"
                >
                    <img
                        class="m-r-4"
                        :src="
                            require(`@/assets/images/icons/${
                                selectedMethod === select.id ? 'radio_on' : 'radio_off'
                            }.png`)
                        "
                        width="24px"
                        height="24px"
                    />
                    <span v-html="select.key" />
                </div>
            </div>
        </div> -->
        <!-- <div class="section" v-if="type === 'asset' && selectedFinanceBtn !== 0">
            <div class="section-title">자산 세부사항</div>
            <TextareaWithX class="textarea" v-model="assetDetail" :placeholder="placeholder" />
        </div> -->
    </div>
</template>

<script>
export default {
    name: 'Finance',
    props: {
        type: String,
        section: String,
        stage: Number,
    },
    data: () => ({
        assetDetail: null,
        value: null,
        selectedFinanceBtn: null,
        selectedMethod: null,
        reset: false,
    }),
    watch: {
        disabled: function () {
            if (this.disabled) {
                this.$emit('disabled', true)
            } else {
                this.$emit('disabled', false)
            }
        },
        stage: function () {
            this.$emit('disabled', this.disabled)
            this.init()
        },
    },
    methods: {
        onChangeNumberVal(val) {
            this.value = val
        },
        onClickBtn(id) {
            this.selectedFinanceBtn = id

            if (id === 0) {
                this.value = 0
                this.assetDetail = null
            } else if (this.type !== 'asset') {
                this.value = null
            }
        },
        onClickSelector(id) {
            this.selectedMethod = id
        },
        onSave(key) {
            const privacy = this.profile.privacy_setting
            privacy[`show_${this.type}`] = this.selectedMethod

            this.$set(this.profile, this.type, this.value)
            if (this.assetDetail) {
                this.$set(this.profile, 'asset_detail', this.assetDetail)
            }

            this.$profile.setPrivacySettings(privacy)
        },
        init() {
            this.value = this.initialVal

            if (this.value !== null) {
                this.selectedFinanceBtn = this.value ? 1 : 0
                this.selectedMethod = this.profile.privacy_setting[`show_${this.type}`]
                this.assetDetail = this.type === 'asset' && this.profile.asset_detail

                if (this.initialVal === 0) this.selectedFinanceBtn = 0
            } else {
                // } else {
                this.selectedFinanceBtn = null
                this.selectedMethod = null
                this.assetDetail = null
            }
        },
    },
    mounted() {
        this.init()
    },
    computed: {
        disabled() {
            if (this.type === 'asset') {
                if (this.value === 0) return false
                else return this.value === null
            } else return this.value === null
        },
        profile() {
            return this.$store.getters.profile
        },
        // placeholder() {
        //     return '구체적으로 어떤 자산을 얼마나 갖고 있는지 적어주세요. 상속 예정인 내용을 적어주셔도 좋아요. (예금 등 현금성 자산, 주식, 부동산 등)'
        // },
        initialVal() {
            return Number(this.profile[this.type]) || null
        },
        financeButtons() {
            return [
                {
                    id: 0,
                    key: `${this.type === 'income' ? '소득' : '자산'}없음`,
                },
                {
                    id: 1,
                    key: `${this.type === 'income' ? '소득' : '자산'} 있음`,
                },
            ]
        },
        selectors() {
            return [
                {
                    id: 0,
                    key: '비공개(매니저에게만 공개)',
                    selectedFinanceBtn: [0, 1],
                },
                {
                    id: 2,
                    key: `구간으로 공개 : ${
                        this.type === 'income'
                            ? this.$profile.moneyRangeParsed(this.value)
                            : this.$profile.assetRangeParsed(this.value)
                    }`,
                    selectedFinanceBtn: [1],
                },
                {
                    id: 1,
                    key: `전체 공개 ${this.value ? this.$krwParsed(this.value) : ''}`,
                    selectedFinanceBtn: [0, 1],
                },
            ].filter(item => {
                if (this.selectedFinanceBtn === null) return true

                return item.selectedFinanceBtn.includes(this.selectedFinanceBtn)
            })
        },
    },
}
</script>
